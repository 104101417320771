


























import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			clubs: [] as any[],
			filterClub: [] as any[],
			searchTimer: 0 as number,
			search: ""
		};
	},
	created() {
		this.$store.commit("pushLoading", {
			name: "GET_ALL_CLUB",
			message: "로그인 중"
		});
		this.$store
			.dispatch("GET_ALL_CLUB")
			.then(clubs => {
				this.$store.commit("clearLoading", "GET_ALL_CLUB");
				this.clubs = clubs;
				this.filterClub = clubs;
			})
			.catch(err => {});
	},
	methods: {
		getImgPath(imgPath: string) {
			if (imgPath) return this.$store.state.mainPath + imgPath;
			else
				return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		}
	},
	watch: {
		search() {
			if (this.searchTimer) {
				clearTimeout(this.searchTimer);
			}
			this.searchTimer = setTimeout(() => {
				this.filterClub = this.clubs.filter(
					(club: any) =>
						club.name
							.toLowerCase()
							.indexOf(this.search.toLowerCase()) != -1 ||
						club.school
							.toLowerCase()
							.indexOf(this.search.toLowerCase()) != -1
				);
			}, 300);
		}
	}
});
